// src/components/Cart.js

import React from 'react';
import { useRecoilValue } from 'recoil';
import { cartState, cartTotalState } from '../recoil/atoms';

const Cart = ({carts}) => {
  const cart = useRecoilValue(cartState);
  const cartTotal = useRecoilValue(cartTotalState);

  return (<>
    {cart.length>=1 &&<div className="cart fixed z-50 bg-gray-100 p-4 mt-16 w-1/4 h-screen " style={{ right:carts,transition: '1s' }}>
     <h2 style={{ display:'flex',width:"80%",justifyContent:'space-between',margin:'auto'}}>Shopping Cart</h2>
      <ul style={{ fontFamily:"arial",fontSize:"14px", fontWeight:500 }}>
        {cart.map((item, index) => (
          <li key={index} style={{padding:'20px 0', display:'flex',width:"80%",justifyContent:'space-between',margin:'auto'}}><div>{item.name} :</div><div>{item.cartqty} </div><div>x</div><div>{item.price}</div>  </li>
        ))}
      </ul>
      <div style={{ display:'flex',width:"80%",justifyContent:'space-between',borderTop:'1px solid #333' ,margin:'14px auto'}}><div>Total :</div><div>₹{cartTotal}</div></div>
      <div style={{ display:'flex',width:"80%",justifyContent:'space-between' ,margin:'14px auto'}}>
      <button
      className="rounded-md bg-black hover:bg-gray-700 active:scale-95 p-2 text-white"
      style={{ margin:'auto',width:'100%' }}
      >Checkout</button>
      </div>
      
    </div>}
    </>
  );
};

export default Cart;
