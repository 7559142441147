

  var Menu =[
    {
    id:1,
    name:"product-1"
    },
    {
      id:2,
      name:"product-2"
      }, 
  ]

  export default Menu;
