import { atom } from 'recoil';

export const cartState = atom({
  key: 'cartState',
  default: [],
});

export const cartTotalState = atom({
  key: 'cartTotalState',
  default: 0,
});