const Products = [
    {
      id: 1,
      name: 'Earthen Bottle',
      href: 'single/1',
      price: 480,
      imageSrc:'https://m.media-amazon.com/images/I/71qKhR3RVIL.jpg',
      imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
      cat:["cat1"],
      quantity:100,
      minqty:1
    },
    {
      id: 2,
      name: 'Nomad Tumbler',
      href: 'single/2',
      price: 350,
      imageSrc: 'https://m.media-amazon.com/images/I/71qKhR3RVIL.jpg',
      imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
      cat:"cat2",
      quantity:100,
      minqty:1
    },
    {
      id: 3,
      name: 'Focus Paper Refill',
      href: 'single/3',
      price: 890,
      imageSrc:'https://m.media-amazon.com/images/I/71qKhR3RVIL.jpg',
      imageAlt: 'Person using a pen to cross a task off a productivity paper card.',
      cat:"cat1",
      quantity:100,
      minqty:1
    },
    {
      id: 4,
      name: 'Machined Mechanical Pencil',
      href: 'single/4',
      price: 350,
      imageSrc:'https://m.media-amazon.com/images/I/71qKhR3RVIL.jpg',
      imageAlt: 'Hand holding black machined steel mechanical pencil with brass tip and top.',
      cat:"cat2",
      quantity:100,
      minqty:1
    },
    {
      id: 5,
      name: 'Nomad Tumbler',
      href: 'single/5',
      price: 350,
      imageSrc:'https://m.media-amazon.com/images/I/71qKhR3RVIL.jpg',
      imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
      cat:"cat1",
      quantity:100,
      minqty:1
    },
    {
      id: 6,
      name: 'Focus Paper Refill',
      href: 'single/6',
      price: 890,
      imageSrc:'https://m.media-amazon.com/images/I/71qKhR3RVIL.jpg',
      imageAlt: 'Person using a pen to cross a task off a productivity paper card.',
      cat:"cat2",
      quantity:100,
      minqty:1
    },
    {
      id: 7,
      name: 'Earthen Bottle',
      href: 'single/7',
      price: 480,
      imageSrc:'https://m.media-amazon.com/images/I/71qKhR3RVIL.jpg',
      imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
      cat:"cat1",
      quantity:100,
      minqty:1
    },
    
  ];

 


  export default Products;