export const CarouselData = [
    {
      image:
        "https://sportsun.in/cdn/shop/files/slider-4_f2c19e76-c3e0-44c3-ae86-3aa4dfd3e871.jpg?v=1683892259",
    },
    {
      image:
        "https://sportsun.in/cdn/shop/files/slider-3.jpg?v=1683797931",
    },
    {
      image:
        "https://sportsun.in/cdn/shop/files/slider-18_3eb0ead7-f5e0-4309-a80e-064b82e1947a.jpg?v=1683892242",
    },
    {
      image:
        "https://furosports.com/media/catalog/category/Mens_Tshirt.jpeg",
    },
    {
      image:
        "https://cdn11.bigcommerce.com/s-q74p3jmgwr/images/stencil/original/carousel/119/Carousel_Custom_Printing_Banner_Rev_Field_OFC_1280x490x72ppi.jpg?c=1",
    },
  ];