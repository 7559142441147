import React, { useState } from 'react'
import Navbar from '../components/nav';
import PageRoute from './PageRoute';
import Footer from '../components/footer';
import Cart from '../components/Cart';

export default function Front() {
    const [cartV,setcartv] = useState(false);
    const [carts,setcarts] = useState(0);
    const cartvis =()=>{
      var b = cartV?"-25%":0;
     if (!cartV) {
      setcartv(!cartV);
      setTimeout(() => {
        setcarts(b)
      }, 500);
     } else {   
      setcarts(b)
        setTimeout(() => {
          setcartv(!cartV)
        }, 500);
     }  
        
    }
  return (
    <div> 
    {cartV &&<div onClick={cartvis} style={{ position:'absolute', width:"100%",height:'100vh' }}><Cart carts={carts}  /></div>}
    <Navbar cartvis={cartvis} />
    
    <PageRoute />  
    <Footer />
    </div>
  )
}
