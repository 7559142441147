import React, { useEffect, useState } from 'react'
import Category from '../components/Category'
import Banner from '../components/banner'
import {Routes,Route} from 'react-router-dom'
import Products from "../components/products";
import SingleProductPage from '../components/SingleProductPage';
import Login from './login';
import Register from './register';
import Slider from '../components/slider';
import Carousel from '../components/carusol';


export default function PageRoute() {
   
    
  return (
    <div>
    <Routes>
        <Route path="/" element={
            <>
            <Carousel />
            <Category />
            </>
        } ></Route>
        <Route path="/single/:id" element={<SingleProductPage prd={Products} />} />
        <Route path="/auth/login" element={<Login />} />
        <Route path="/auth/register" element={<Register />} />
        
    </Routes>
    </div>
  )
}
