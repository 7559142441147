import React, { useState ,useEffect, useRef} from 'react';
import { useRecoilState } from 'recoil';
import { cartState, cartTotalState } from '../recoil/atoms';
import { fabric } from 'fabric';
import {Route, Link, Routes, useParams} from 'react-router-dom';
import { prototype } from 'postcss/lib/declaration';

const SingleProductPage = ({prd}) => {
//   const canvasRef = useRef(null);
const params = useParams();

const [prdid,setprdid] = useState(prd[0])
useEffect(() => {
    var prrd = prd.find(pd=> parseInt(pd.id) === parseInt(params.id) )
 setprdid(prrd);
}, [])

    const [qty, setqty] = useState(1);
    const [cart, setCart] = useRecoilState(cartState);
    const [cartTotal, setCartTotal] = useRecoilState(cartTotalState);
    const [IImg, setIimg] = useState('https://g8j3w4f6.rocketcdn.me/wp-content/uploads/2020/07/1.png');
    const [text, setText] = useState('Enter text here');
    const [textsize, setTextsize] = useState(20);

    const addToCart = () => {
      var upditem = cart.find(it=>it.id===prdid.id);
   var updatedCart=[]   
     console.log(cart);
      if (upditem) {
        var index = cart.findIndex(obj => {
          return obj.id === prdid.id;
        });
        
        var pp = {...upditem}
        pp.cartqty += 1;
        updatedCart = [...cart]
        updatedCart[index] = pp;

      } else {
        prdid.cartqty = 1;
         updatedCart = [...cart, prdid];
      }
      

      const updatedTotal = cartTotal + prdid.price;
      setCart(updatedCart);
      setCartTotal(updatedTotal);
    };
    useEffect(() => {
      var canvas = new fabric.Canvas('canvas', {
        width: 400,
        height: 400,
      });
  
        fabric.Image.fromURL(IImg, (img) => {
       
          if (img.width>img.height) {
            var scale = 100/ img.width
          } else {
            var scale = 100/ img.height
          }
          img.set({
            left: 150,
            top: 100,
            scaleX: scale,
            scaleY: scale,
            selectable: false,
            evented: false,
          });
          img.center();
      canvas.add(img);
        
      
  
      const textbox = new fabric.Textbox(capT(text), {
        left: 155,
        top: 220,
        width:120,
        fontSize: textsize,
        selectable: false,
            evented: false,
      });
  
      canvas.add(textbox);
     
    }); 
    
    return () => {
      canvas.renderAll();
    };
}, [IImg,text,textsize]);
  const ImgChange =(e)=>{
    setIimg(e.target.value)
  }
  const handleqty = (event) => {
    setqty(event.target.value);
  };
  const handleTextChange = (event) => {
    setText(event.target.value);
  };
  const handleTextSize = (event) => {
    setTextsize(event.target.value);
  };
function capT(str) {
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");
  return str2;
}



  return (
    <div className="bg-gray-100 min-h-screen py-12">
      {prd &&<div><div className="container mx-auto p-6 bg-white rounded-lg shadow-lg">
        <div className="md:flex" >
          {/* Product Image */}
          <div className="md:w-1/2" >
          <div className='m-auto' style={{ position:"relative" , width:"400px", height:"400px" }} >
          <div className='CanvasCont mx-auto' style={{ position:"absolute" }}>
          <canvas id="canvas"></canvas>
          </div>
            <img
               src={prdid.imageSrc}
                    alt="Thumbnail 1"
              className="w-96 mx-auto rounded-lg"
            />
          </div>
          </div>

          {/* Product Details */}
          <div className="md:w-1/2 md:pl-6">
            <h1 className="text-3xl font-bold mb-4">{prdid.name}</h1>
            <p className="text-gray-600 mb-6">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla at
              nulla justo.
            </p>
            <div className="flex items-center mb-4">
              <span className="text-2xl font-semibold text-gray-600 mr-2">
              {prdid.price}
              </span>
              <span className="text-gray-500">List Price $29.99</span>
            </div>
            <div className="flex mb-4">
              <span className="text-gray-600 mr-4">Color: Black</span>
              <span className="text-gray-600">Size: M</span>
            </div>
            <div className="mb-6">
              <label className="text-gray-600">Quantity:</label>
              <input
                type="number"
                className="w-16 border rounded-md py-2 px-3"
                min="1"
                value={qty}
                onChange={handleqty}
              />
            </div>
            <input type='text' className='my-2 mr-4' value={text} onChange={handleTextChange} />
            <input type='number' className='my-2' value={textsize} onChange={handleTextSize} />


            <br></br>
            <select className='my-2' onChange={ImgChange}>
            <option value="https://g8j3w4f6.rocketcdn.me/wp-content/uploads/2020/07/1.png">image 1</option>
            <option value="https://g8j3w4f6.rocketcdn.me/wp-content/uploads/2020/07/nessun-minimo-dordine.png" >image 2</option>
            <option value="https://g8j3w4f6.rocketcdn.me/wp-content/uploads/2020/07/design-personlaizzato.png">image 3</option>
            
            </select><br></br>
            <button
          onClick={addToCart}
          className="rounded-md bg-black hover:bg-gray-700 active:scale-95 p-2 mt-6 w-64 text-white"
        >
          Add to Cart
        </button>
          </div>
        </div>
      </div>
      <div className="container mx-auto p-6 bg-white rounded-lg shadow-lg">
      <div className="md:flex"></div>
      </div>
      </div>}
    </div>
  );
};

export default SingleProductPage;
