
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faSearch , faCartFlatbed} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom"

import Logo from "./Logo";
import Menu from './GetData'

import { useState } from "react";

export default function Navbar({cartvis}){

  
    return(
        <>
        <nav className="bg-white h-120 py-50 shadow-lg fixed w-full z-50">

        <div className="max-w-contain mx-auto flex items-center">
        <Link to={'/'} className="p-4 h-18 text-gray-500 hover:text-gray-900 text-lg font-medium cursor-pointer flex-auto" >
        <Logo />
        </Link>
        <div className="flex flex-auto">
        {Menu.map((menu,ind)=> <Link key={ind} to={"/single/"+menu.id} className="p-4 h-18 text-gray-500 hover:text-gray-900 text-lg font-medium cursor-pointer" >
        {menu.name}
        </Link>)}
        </div>

        <div className="p-4 h-18 text-gray-500 hover:text-gray-900 text-lg font-medium cursor-pointer" >
        <FontAwesomeIcon icon={faCartFlatbed} className="fa-regular fa-cart-shopping" onClick={cartvis}></FontAwesomeIcon>
        
        

        </div>
        <div className=" p-4 h-18 text-gray-100 hover:text-white text-lg font-medium cursor-pointer" >
       <Link to={'/auth/login'} className="rounded-md bg-black hover:bg-gray-600 active:scale-95 p-2 ">login</Link> 
        </div>
        </div>
        </nav>
        <div className="pt-[0.1rem] bg-white  w-full" >
        <div className="max-w-contain mx-auto flex justify-end  relative">
        <input type="Search" className="p-2 m-2 w-[300px] rounded-full" placeholder="Search for product ..."/>
        <FontAwesomeIcon icon={faSearch} className="absolute top-0 bottom-0 right-[20px] m-auto"></FontAwesomeIcon>
        </div>
        </div>
        
        </>

    )
};