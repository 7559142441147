import './App.css';
import Navbar from './components/nav';
import Footer from './components/footer';
import PageRoute from './page/PageRoute';
import SliderComponent from './components/slide';
import Cart from './components/Cart';
import { useState } from 'react';
import Front from './page/front';
import Admin from './page/admin';
import { Route, Routes } from 'react-router-dom';

function App() {
 
  return (
    <div>
    <Routes>
    <Route path="/*" element={<Front />}/>
    <Route path="/admin/*" element={<Admin />} />
    </Routes>
    </div>
  );
}

export default App;
